/* eslint-disable import/prefer-default-export */
/**
 * Get options of years for expiration date.
 * 5 years from current year.
 */
export const getYears = () => {
  const fullYear = new Date().getFullYear();
  let year = +`${fullYear}`.slice(-2);

  return Array(6)
    .fill(null)
    .map(() => {
      const yearOption = {
        label: year,
        value: year,
      };

      year += 1;

      return yearOption;
    });
};
