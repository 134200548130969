<template>
  <v-dialog max-width="1000" :value="show" @input="close">
    <Form @submit="submit">
      <v-card>
        <v-card-title class="headline"> Formulario de pago </v-card-title>

        <v-card-text>
          <v-alert
            v-model="notification.show"
            class="notification"
            :type="notification.type"
            transition="scale-transition"
          >
            {{ notification.message }}
          </v-alert>

          <v-row class="mb-14">
            <v-col cols="12">
              <h1 class="blue--text text-center">TOTAL A PAGAR: {{ amount | currency }}</h1>
            </v-col>

            <v-col cols="12" sm="6">
              <TextField
                autocomplete="off"
                label="Nombre del titular (como aparece en la tarjeta) *"
                prepend-icon="mdi-account"
                rules="required"
                :value="form.holder_name"
                @input="form.holder_name = $event.toUpperCase()"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <TextField
                v-model="form.card_number"
                autocomplete="off"
                maxlength="16"
                label="Número de tarjeta *"
                prepend-icon="mdi-credit-card"
                rules="required|integer|min:15|max:16"
              />
            </v-col>

            <v-col cols="6" sm="3">
              <SelectField
                v-model="form.expiration_month"
                :items="months"
                item-text="label"
                item-value="value"
                label="Mes de expiración *"
                prepend-icon="mdi-calendar"
                rules="required"
              />
            </v-col>

            <v-col cols="6" sm="3">
              <SelectField
                v-model="form.expiration_year"
                :items="years"
                item-text="label"
                item-value="value"
                label="Año de expiración *"
                prepend-icon="mdi-calendar"
                rules="required"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <TextField
                v-model="form.cvv2"
                autocomplete="off"
                maxlength="3"
                label="Código de seguridad (3 dígitos) *"
                prepend-icon="mdi-lock"
                rules="required|integer|length:3"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="4">
              <h4>Tarjetas de crédito aceptadas</h4>
              <img class="image-cards" src="@/assets/img/payment/credit_cards.png" />
            </v-col>

            <v-col cols="12" sm="8">
              <h4>Tarjetas de débito aceptadas</h4>
              <img class="image-cards" src="@/assets/img/payment/debit_cards.png" />
            </v-col>

            <v-col class="d-flex align-center" cols="12">
              <p class="mr-3">Transacciones realizadas vía:</p>
              <img class="image-cards" src="@/assets/img/payment/openpay.png" />
            </v-col>

            <v-col class="d-flex align-center" cols="12">
              <img class="image-cards" src="@/assets/img/payment/security.png" />
              <p class="ml-2">Tus pagos se realizan de forma segura con encriptación de 256 bits</p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="close"> Cancelar </v-btn>
          <v-btn color="success" type="submit" :disabled="processingPayment"> Pagar </v-btn>
        </v-card-actions>
      </v-card>
    </Form>
  </v-dialog>
</template>

<script>
// API
import OrderAPI from '@/api/order';

// Config
import config from '@/config';

// Utils
import { getYears } from '@/utils/payment';

const initialNotification = () => ({
  show: false,
  type: 'error',
  message: '',
});

export default {
  name: 'Payment',

  props: {
    show: {
      type: Boolean,
      required: true,
    },

    amount: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    form: {
      holder_name: null,
      card_number: null,
      expiration_month: null,
      expiration_year: null,
      cvv2: null,
    },
    deviceSessionId: null,
    months: [
      { label: '01', value: '01' },
      { label: '02', value: '02' },
      { label: '03', value: '03' },
      { label: '04', value: '04' },
      { label: '05', value: '05' },
      { label: '06', value: '06' },
      { label: '07', value: '07' },
      { label: '08', value: '08' },
      { label: '09', value: '09' },
      { label: '10', value: '10' },
      { label: '11', value: '11' },
      { label: '12', value: '12' },
    ],
    years: getYears(),
    notification: initialNotification(),
    processingPayment: false,
    token: null,
  }),

  created() {
    this.api = new OrderAPI();
  },

  mounted() {
    /* eslint-disable no-undef */
    OpenPay.setId(config.openpay.ID);
    OpenPay.setApiKey(config.openpay.API_KEY);
    OpenPay.setSandboxMode(config.openpay.SANDBOX);
    this.deviceSessionId = OpenPay.deviceData.setup();
    /* eslint-enable no-undef */
  },

  methods: {
    close() {
      this.$emit('update:show', false);
    },

    async submit() {
      this.$store.commit('showLoader');
      try {
        this.resetNotification();
        const token = await this.getToken();
        // eslint-disable-next-line operator-linebreak
        const email =
          this.$store.getters.profile?.email || this.$store.state.signup.customer?.email;
        await this.api.processPayment({
          token,
          amount: this.amount,
          customer: {
            email,
            name: this.form.holder_name,
          },
          device_session_id: this.deviceSessionId,
        });
        this.$emit('save');
      } catch (error) {
        console.log(error);
        let message = '';

        switch (error.data.error_code) {
          case 2004:
            message = 'Número de tarjeta inválido';
            break;

          case 2005:
            message = 'Fecha inválida';
            break;

          default:
            message = 'No se pudo completar el pago. Por favor, intenta otra vez.';
        }

        this.notification = {
          message,
          show: true,
          type: 'error',
        };
      }
      this.$store.commit('hideLoader');
    },

    async getToken() {
      return new Promise((resolve, reject) => {
        /* eslint-disable no-undef */
        OpenPay.token.create(
          this.form,
          (response) => {
            resolve(response.data.id);
          },
          (error) => {
            reject(error);
          },
        );
        /* eslint-enable no-undef */
      });
    },

    resetNotification() {
      this.notification = initialNotification();
    },
  },
};
</script>

<style lang="scss" scoped>
.image-cards {
  height: 30px;
  max-width: 100%;
}
p {
  margin: 0;
}
</style>
